// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
import { SetupFailed } from './setup.exceptions';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SetupService = class SetupService {
    // private Sentry: ISentry | null = null
    constructor(connector, identity, navigations, sites) {
        this.connector = connector;
        this.identity = identity;
        this.navigations = navigations;
        this.sites = sites;
    }
    /**
     * Return locale of current site.
     */
    getLocale() {
        return `${this.sites.getActiveSite().locale}`.toLowerCase();
    }
    makeId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
    /**
     * Setup application due to API response.
     */
    async initApp(entryUrl, isClient) {
        isClient ? await this.initClientApp(entryUrl) : await this.initSSRApp(entryUrl);
    }
    /**
     * Determine of restrictions should be turned on.
     */
    isMaintenance() {
        return this.sites.getActiveSite().isMaintenance();
    }
    /**
     * Init SSR app with all required setup.
     * @protected
     */
    async initSSRApp(entryUrl) {
        const data = await this.callApi(entryUrl);
        this.setBasicServices(data);
    }
    /**
     * Init Client app with all required setup.
     * @protected
     */
    async initClientApp(entryUrl) {
        try {
            const data = await this.callApi(entryUrl);
            this.setBasicServices(data);
            this.identity.generateSignature();
        }
        catch (e) {
            if (e instanceof SetupFailed) {
                throw e;
            }
        }
    }
    async callApi(entryUrl, retriesLeft = 2) {
        var _a, _b;
        const token = (_a = this.makeId(8)) !== null && _a !== void 0 ? _a : null;
        const response = await this.connector.call('setup', 'init', {}, {
            referer: entryUrl,
            tokenCheck: token
        });
        if (!response.isSuccessful() || response.status === 0) {
            // if (this.Sentry) {
            //   this.Sentry.addBreadcrumb({
            //     category: 'setup',
            //     type: 'error',
            //     message: response.errors?.message,
            //     level: this.Sentry.Severity.Error,
            //     data: {
            //       ...response,
            //       entryUrl
            //     }
            //   })
            // }
            throw new SetupFailed(((_b = response.errors) === null || _b === void 0 ? void 0 : _b.message) || 'Unknown error', response.status);
        }
        else {
            // if (this.Sentry) {
            //   this.Sentry.addBreadcrumb({
            //     category: 'setup',
            //     type: 'debug',
            //     message: '[SetupService] initCall result',
            //     level: this.Sentry.Severity.Debug,
            //     data: {
            //       ...response,
            //       entryUrl
            //     }
            //   })
            // }
            // temporary maybe-fix for site mismatch
            if (!this.validateSiteResponse(entryUrl, token, response.data, 3 - retriesLeft) && retriesLeft > 0) {
                return this.callApi(entryUrl, retriesLeft - 1);
            }
        }
        return response.data;
    }
    validateSiteResponse(entryUrl, token, data, retryNumber) {
        const activeSiteId = data.currentSite;
        // const responseToken = data.tokenCheck ?? null
        const sites = Object.values(data.sites).map(site => ({
            id: site.id,
            name: site.name,
            address: site.address
        }));
        const activeSite = sites.find(site => site.id === activeSiteId);
        if (!activeSite) {
            // if (this.Sentry) {
            //   this.Sentry.captureException(
            //     new Error(`[SetupService]: Site mismatch: requested ${entryUrl}, got ${activeSiteId}, not found in sites. Retry number: ${retryNumber}`),
            //     {
            //       extra: {
            //         entryUrl,
            //         activeSiteId,
            //         sites,
            //         retryNumber,
            //         token,
            //         responseToken
            //       }
            //     }
            //   )
            // }
            return false;
        }
        const isValid = entryUrl.includes(activeSite.address.replace('http://', '').replace('https://', ''));
        // if (this.Sentry && !isValid) {
        //   this.Sentry.captureException(
        //     new Error(`[SetupService]: Site mismatch: requested ${entryUrl}, got ${activeSite.name} [${activeSite.id}]. Retry number: ${retryNumber}`),
        //     {
        //       extra: {
        //         entryUrl,
        //         activeSiteId,
        //         activeSite,
        //         sites,
        //         retryNumber,
        //         token,
        //         responseToken
        //       }
        //     }
        //   )
        // }
        return isValid;
    }
    setBasicServices(data) {
        this.navigations.parseNavigations(data.navs);
        this.navigations.setSubscriptionButtonState(data.hideSubscriptionButton);
        this.navigations.setPremiumButtonLink(data.premiumLink);
        this.sites.setSites(data.sites, data.searchLabels);
        this.sites.setActive(data.currentSite);
        this.sites.setFaqUrl(data.faqUrl);
        this.sites.setAdsConfig(data.adsConfig);
        this.sites.setArticlePrices(data.articlePrices);
        this.sites.setPromoCodesConfig(data.promoCodes);
        this.sites.setWelcomeFormConfig(data.welcomeForm);
        this.sites.setSiteImage(data.siteImage);
    }
};
SetupService = __decorate([
    Injectable()
], SetupService);
export { SetupService };
